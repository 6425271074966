import "./App.css";
import React from "react";
import Nav from "./pages/Nav/Nav";
import Main2 from "./pages/Main/Main2";
import Footer from "./pages/Footer/Footer";
import Hero from "./pages/Hero/Hero";

function App() {
  return (
    <div className="home">
      <Nav />
      <Hero />
      <Main2 />
      <Footer />
    </div>
  );
}

export default App;
